import React from 'react'
import { Link } from 'gatsby'
import Logo from './logo'

const Header = () => (
  <div
    style={{
      backgroundColor: '#fafafa',
      boxShadow: '0 0 3px 0 rgba(0,0,0,0.22)',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 300,
        padding: '1.45rem 1.0875rem',
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          <Logo />
        </Link>
      </h1>
    </div>
  </div>
)

export default Header
